<template>
  <TitleWrapper
    :filter-option="true"
    :title="title"
    :tooltip-title="tooltipTitle"
    :display-breadcrumb="showBreadcrumb"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiSingleSelect
          v-model.trim="filtersPayload.student_id"
          title="STUDENT"
          :options="studentList"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="full_name"
          :search-through-api="true"
          reduce="id"
          :filter="FILTERS_LIST.STUDENT"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-if="showPickupPersonFilter"
          v-model.trim="filtersPayload.pickup_person_id"
          title="PICKUP_PERSON"
          :options="pickupPersonList"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="full_name"
          :search-through-api="true"
          reduce="id"
          :filter="FILTERS_LIST.PICKUP_PERSON"
          @search="getPickupPersons"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-if="showStatusFilter"
          v-model.trim="filtersPayload.status"
          title="STATUS"
          :options="requestStatuses"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'status',
          }"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-if="!showStatusFilter"
          v-model.trim="filtersPayload.activity_log_status"
          title="RESPONSES"
          :options="requestResponses"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="{ option: FILTER_KEYS.EQUAL, key: 'activity_log_status' }"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import filterMixins from '@/src/mixins/filter-mixins.js'
import {
  PICKUP_REQUEST_STATUS,
  PICKUP_REQUEST_ALL_RESPONSES,
  PICKUP_REQUEST_ACTIVE_RESPONSES,
} from '@src/constants/pickup-constants.js'
import { mapState, mapActions } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import { getCountFromObject } from '@utils/generalUtil'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
    showStatusFilter: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    feature: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FILTER_KEYS,
      FILTERS_LIST,
      studentList: [],
      pickupPersonList: [],
      $where: {},
      filtersCount: 0,
      forceRender: 0,
      TENANT_ROLES,
      requestStatuses: PICKUP_REQUEST_STATUS,
      dashboard: 'dashboard',
      filtersPayload: {
        status: '',
        student_id: '',
        pickup_person_id: '',
        activity_log_status: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    requestResponses() {
      return this.showStatusFilter ? PICKUP_REQUEST_ALL_RESPONSES : PICKUP_REQUEST_ACTIVE_RESPONSES
    },
    showBreadcrumb() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    showPickupPersonFilter() {
      return this.activeRole !== TENANT_ROLES.PICKUP_PERSON
    },
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope || null,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
        this.getPickupPersons()
      },
    },

    currentSectionScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
        this.getPickupPersons()
      },
    },
  },
  created() {
    if (this.feature === 'Student Dispatcher') {
      this.requestResponses[0].title = 'Request is Received'
    } else this.requestResponses[0].title = 'Request has Sent'
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getPickupPersons()
    this.getStudents()
  },

  methods: {
    getStudents(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      if (this.showPickupPersonFilter) this.getSectionStudents(payload)
      else this.getPikcupAssosiatedStudents(payload)
    },
    getPikcupAssosiatedStudents(payload) {
      this.getAssociatedStudents(payload).then((res) => {
        this.studentList = res?.records
      })
    },
    getSectionStudents(payload) {
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res?.records
      })
    },
    getFiltersCount() {
      this.getStudents()
      this.getPickupPersons()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    getPickupPersons(query = '') {
      if (!this.showPickupPersonFilter) return
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getPickupMembers(payload).then((res) => {
        this.pickupPersonList = res?.records
      })
    },
    ...mapActions('pickup', ['getAssociatedStudents', 'getPickupMembers']),
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
