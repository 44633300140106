export const PICKUP_REQUEST_STATUS = [
  { title: 'Active', value: 'active' },
  { title: 'Inactive', value: 'inactive' },
  { title: 'Processed', value: 'picked' },
  { title: 'Closed', value: 'closed' },
  { title: 'Expired', value: 'expired' },
  { title: 'Rejected', value: 'rejected' },
]
export const PICKUP_REQUEST_ACTIVE_RESPONSES = [
  { title: 'Request has Sent', value: 'request_sent' },
  { title: 'Request is Acknowledged', value: 'acknowledged' },
  { title: 'Rerequested', value: 'rerequested' },
  { title: 'Student is on the way. Be ready!', value: 'student_coming' },
  { title: 'Student is coming shortly. Hang tight!', value: 'student_coming_in_few_mins' },
  { title: 'Student is engaged right now. Please come back later', value: 'student_busy' },
  { title: 'Custom Message', value: 'custom_message' },
]
export const PICKUP_REQUEST_ALL_RESPONSES = [
  { title: 'Request has Sent', value: 'request_sent' },
  { title: 'Rejected', value: 'request_denied' },
  { title: 'Request Closed', value: 'request_closed' },
  { title: 'Request Expired', value: 'request_expired' },
  { title: 'Student has been Picked', value: 'student_picked' },
  { title: 'Request is Acknowledged', value: 'acknowledged' },
  { title: 'Rerequested', value: 'rerequested' },
  { title: 'Student is on the way. Be ready!', value: 'student_coming' },
  { title: 'Student is coming shortly. Hang tight!', value: 'student_coming_in_few_mins' },
  { title: 'Student is engaged right now. Please come back later', value: 'student_busy' },
  { title: 'Custom Message', value: 'custom_message' },
]
